@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/elevation';

.member-search {
  $breakpoint: 1000px;
  position: relative;

  &__result-wrapper {
    // NOTE: Custom shadow because of the connecting component
    box-shadow:
      0 -1px 18px #00000000,
      0 6px 10px #0000001a,
      0 3px 5px #0000001a;
    position: absolute;
    top: 100%;
    z-index: 10;
    border-radius: corner-radius.$s;
    max-height: 450px;
    overflow: auto;
    background: light.$surface-primary-default;
    width: 100%;
  }

  &__results {
    display: flex;
    flex-direction: column;
  }

  &__result {
    border: none;
    background: none;
    padding: spacing.$m;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: spacing.$xxs;
    text-align: left;
    border-bottom: 1px solid light.$ge-divider-default;

    &:hover {
      background: light.$surface-primary-hover;
    }
  }

  &__result-footer {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    padding: spacing.$s spacing.$m;
    display: flex;
    gap: spacing.$s;
    border-top: 1px solid light.$ge-divider-alternate;
    background: light.$surface-secondary-default;
  }

  &__result-content {
    display: flex;
    gap: spacing.$s;
    flex-direction: column;
  }

  &__result-footer-icon {
    padding-top: spacing.$xxs;
  }

  &__result-top {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$xxs;
  }

  &__phone {
    color: light.$on-background-primary-alternate;
  }

  &__no-result {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
    align-items: center;
    padding: spacing.$m;
    text-align: center;
  }
}
